/* starts of card view section */
.blog-post-card {
  display: inline-block;
  border-radius: 5px;
  width: 31%;
  margin: 1%;
  background: #fff;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.blog-post-card:hover {
  -webkit-box-shadow: 3px 3px 5px -1px rgba(0, 0, 0, 0.61);
  -moz-box-shadow: 3px 3px 5px -1px rgba(0, 0, 0, 0.61);
  box-shadow: 3px 3px 5px -1px rgba(0, 0, 0, 0.61);
  cursor: default;
}

.card-image-div {
  position: relative;
  width: 100%;
  height: fit-content;
  overflow: hidden;
  background: #fff;
}

.card-images {
  position: relative;
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.card-images:hover {
  transition: transform 0.5s ease;
  transform: scale(1.1);
}

.title-div {
  height: 75px;
  overflow: hidden;
}

.sub-title-div {
  height: 113px;
  overflow: hidden;
}

.publish-date-div {
  height: 30px;
  overflow: hidden;
}

.publish-date-div .publish-date {
  margin: 5px 20px 12px 20px;
  font-size: 13px;
  font-weight: 600;
  float: right;
  font-style: italic;
  color: #3a3a3a;
}

.title {
  color: #353535;
  font-size: 18px;
  margin: 30px 20px 12px 20px;
  font-family: Gordita, sans-serif;
  font-weight: 700;
  line-height: 1.2em;
}

.sub-title {
  font-size: 14px;
  color: #4f4f4f;
  margin: 5px 20px 12px 20px;
  font-family: Gordita, sans-serif;
  line-height: 1.5em;
  text-align: justify;
}

.read-more-btn {
  background: #f26666;
  color: #fff;
  border-radius: 3px;
  margin: 5px 20px 12px 20px;
  padding: 6px 25px;
  font-size: 13px;
  font-weight: 500;
  display: block;
  text-align: center;
}

.read-more-btn:hover {
  background: #d65353;
  color: #fff;
  text-decoration: none;
}

@media (max-width: 480px) {
  .blog-post-card {
    width: 100%;
    margin: 10px 0;
  }

  .title-div {
    height: auto;
  }

  .title {
    height: auto;
  }

  .sub-title-div {
    height: auto;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .blog-post-card {
    width: 46%;
    margin: 2%;
  }

  .title-div {
    height: 95px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .blog-post-card {
    width: 46%;
    margin: 2%;
  }
}
/* ends of card view section */

.jumbotron-img-container {
  max-height: 350px;
  overflow: hidden;
}

.single-post-header-container {
  max-width: 900px;
  margin: auto;
  padding: 0 30px;
}

.jumbotron-img-container {
  max-height: 350px;
  overflow: hidden;
}

.single-post-container {
  max-width: 750px;
}

.single-post-container p {
  text-align: justify;
}

/* .post-title-block {
  max-width: 765px;
    margin: auto;
} */

.post-title {
  font-weight: 600;
  text-align: center;
}

.back-to-blog-link-header {
  display: block;
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 2;
  color: #fff;
  font-size: 14px;
}

.back-to-blog-link-header:hover {
  font-weight: 600;
  text-decoration: none;
  color: #fff;
}

.back-to-blog-link {
  display: none;
  color: #989898;
  margin-top: 12px;
  font-size: 14px;
  font-weight: 600;
}

.back-to-blog-link:hover {
  color: #4e4e4e;
  text-decoration: none;
}

.post-sub-title {
  background: #fff;
  padding: 10px 20px;
  border-left: 5px solid #f26666;
}

.post-heading {
  font-weight: 600;
}

.posting-date {
  font-size: 14px;
  font-weight: 600;
  float: right;
  font-style: italic;
}

.published {
  color: #272727;
  font-weight: 400;
}

@media (max-width: 575px) {
  .post-title {
    text-align: left;
  }

  /* .back-to-blog-link-header {
    display: none;
  } */
/* 

  .back-to-blog-link {
    display: block;
  } */
}

@media (min-width: 576px) and (max-width: 1199px) {
  .jumbotron-img-container {
    max-height: 250px;
    overflow: hidden;
  }
}
  

/* starts of pagination*/
.shop-pagination {
  float: right;
}

.shop-pagination ul li button {
  background: #fff;
  color: #f26666;
  border-color: #f26666;
}

.shop-pagination ul li button:hover {
  background: #f26666;
  color: #fff;
}

.shop-pagination ul li button:focus {
  box-shadow: none;
}
/* ends of pagination*/

/* social media */
.fa-facebook,
.fa-instagram {
  color: #fff;
  margin-right: 10px;
  font-size: 25px;
}
