.get-the-app #am-a-business-link {
    display: block;
    position: absolute;
    top: 30px;
    right: 30px;
    color: #fff !important;
    font-weight: 500;
    padding: 6px 15px !important;
    border-radius: 4px;
    font-size: 16px;
    border: 1px solid #fff;
    letter-spacing: .5px;
    z-index: 1;
  }
  
  .get-the-app #am-a-business-link:hover {
    text-decoration: none;
    color: #000 !important;
    border: 1.5px solid #000;
    font-weight: 600;
  }
  
  
  
  /* starts of get the app modal */
  /* .get-the-app .modal-close {
    width: 35px;
    height: 35px;
    border: 1.5px solid #fff !important;
    border-radius: 50%;
    color: #fff;
    padding: 3px 5px 5px 5px !important;
    float: right;
    position: absolute;
    top: 25px;
    right: 24px;
  }
  
  .get-the-app .modal-close:hover {
    border: 1.5px solid #000 !important;
    color: #000;
    cursor: pointer;
  } */
  
  .get-the-app {
    min-width: 100vw;
    min-height: 100vh;
    margin: 0;
    background-image: linear-gradient(#ff6961, #b34444);
  }
  
  .get-the-app .modal-content-row {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
  }
  
  .get-the-app .user-app-img {
    display: block;
    width: 480px;
    margin: auto;
  }
  
  .get-the-app .tagline {
    line-height: 1;
    text-align: center;
    color: white;
    font-weight: 700;
    font-size: 4.5em;
    margin-top: 30px;
  }
  
  .get-the-app p i {
    font-size: 13px;
    padding-right: 2px;
  }
  
  .get-the-app p {
    font-size: 1em;
    font-style: italic;
    text-align: center;
    color: white;
    margin-top: 190px;
  }
  
  .get-the-app .success-fail-message {
    font-family: "Google Sans", Arial, sans-serif;
    font-size: 14px !important;
    font-style: normal !important;
    margin-top: 250px !important;
  }
  
  .get-the-app .send-phone-nub-div {
    position: relative;
    max-width: 375px;
    margin: auto;
    padding: 0 0 40px 0;
  }
  
  .get-the-app .react-tel-input {
    position: absolute;
  }
  
  .get-the-app .react-tel-input .form-control {
    border: 0;
    box-shadow: none !important;
    width: 260px !important;
  }
  
  .get-the-app .react-tel-input .country-list .search-emoji {
    display: none;
  }
  
  .get-the-app .react-tel-input .country-list .search-box {
    margin-left: 0;
    padding: 6px 8px 5px;
    width: 97%;
  }
  
  .get-the-app .send-btn {
    position: absolute;
    right: 0;
    display: inline-block;
    color: #fff;
    padding: 4px;
    border: 1.25px solid;
    width: 110px;
    border-radius: 4px;
    outline: 0;
    letter-spacing: 0.5px;
    background: transparent;
  }
  
  .get-the-app .send-btn:hover {
    background: #ff6961;
  }

  .get-the-app .store-div {
    max-width: 320px;
    margin: 20px auto 25px;
  }
  
  @media (max-width: 350px) {
    .get-the-app .send-phone-nub-div {
      max-width: 288px !important;
    }
  
    .get-the-app .react-tel-input .form-control {
      width: 175px !important;
    }
  }
  
  @media (min-width: 351px) and (max-width: 406px) {
    .get-the-app .send-phone-nub-div {
      max-width: 321px !important;
    }
  
    .get-the-app .react-tel-input .form-control {
      width: 207px !important;
    }
  }
  
  @media (max-width: 380px) {
    .get-the-app .user-app-img {
      width: 95% !important;
    }
  
    .get-the-app .tagline {
      font-size: 38px !important;
      margin-top: 0;
    }
  
    .get-the-app .store-div {
      max-width: 260px;
    }
  
    .get-the-app .store-icons {
      width: 120px;
    }
  }
  
  @media (max-width: 767px) {
    .get-the-app {
      padding: 0;
      min-width: 100vw;
      height: auto !important;
    }
  
    .get-the-app .modal-content-row {
      position: unset;
      top: unset;
      left: unset;
      transform: unset;
      width: unset;
    }
  
    .get-the-app .img-col {
      order: 2;
    }
  
    .get-the-app .modal-content-col {
      order: 1;
    }
  
    .get-the-app .user-app-img {
      width: 80%;
      margin-top: 22px;
    }
  
    .get-the-app .tagline {
      font-size: 50px;
      margin-top: 0;
    }
  
    .get-the-app p {
      margin-top: 40px;
    }
  
    .get-the-app .success-fail-message {
      margin-top: 60px !important;
    }
  }
  
  @media (min-width: 768px) and (max-width: 967px) {
    .get-the-app .modal-content-row {
      width: 98%;
    }
  
    .get-the-app .user-app-img {
      width: 370px;
    }
  
    .get-the-app .tagline {
      font-size: 46px;
      margin-top: 27px;
    }
  
    .get-the-app p {
      margin-top: 135px;
    }
  
    .get-the-app .send-phone-nub-div {
      max-width: 323px;
    }
  
    .get-the-app .react-tel-input .form-control {
      width: 210px !important;
    }
  
    .get-the-app .success-fail-message {
      margin-top: 190px !important;
    }
  
    .store-div {
      max-width: 250px !important;
    }
  
    .get-the-app .store-icons {
      width: 115px;
    }
  }
  
  @media (min-width: 968px) and (max-width: 1109px) {
    .get-the-app .user-app-img {
      width: 370px;
    }
  
    .get-the-app .tagline {
      font-size: 50px;
      margin-top: 27px;
    }
  
    .get-the-app p {
      margin-top: 135px;
    }
  
    .get-the-app .send-phone-nub-div {
      max-width: 320px;
    }
  
    .get-the-app .react-tel-input .form-control {
      width: 206px !important;
    }
  
    .get-the-app .success-fail-message {
      margin-top: 190px !important;
    }
  
    .store-div {
      max-width: 250px !important;
    }
  
    .get-the-app .store-icons {
      width: 115px;
    }
  }
  
  @media (min-width: 1110px) and (max-width: 1265px) {
    .get-the-app .user-app-img {
      width: 425px;
    }
  
    .get-the-app .tagline {
      font-size: 3.75em;
      margin-top: 25px;
    }
  
    .get-the-app p {
      margin-top: 162px;
    }
  
    .get-the-app .success-fail-message {
      margin-top: 222px !important;
    }
  }
  
  /* ends of get the app modal */
  