.navbar-client {
  background: #fe6860;
  border: 0;
}

.navbar-collapse ul {
  margin-top: -5px;
}

.user-app-navbar .nav-item {
  color: #fff;
  font-weight: 500;
}

.nav-item:hover {
  color: #fff !important;
  /* font-weight: 700; */
  text-decoration: none;
}

.get-app-btn {
  color: #ff6961 !important;
  padding: 8px 12px 5px 12px !important;
  border-radius: 26px;
  margin-left: 15px;
  border: 0;
  outline: 0;
  background: #fff;
  margin-top: 5px;
}

.get-app-btn:focus {
  outline: 0;
}

.get-app-btn:hover {
  color: #ff6961 !important;
  /* font-weight: 700; */
}

.spritzer-for-bus-link {
  color: #fff;
}


.navbar-toggler:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

@media (max-width: 767px) {
  .company-logo {
    margin-left: 10px;
  }

  .nav-links-mobile {
    text-align: center;
    background: #fff;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10px;
    margin-top: 5px;
  }

  .spritzer-for-bus-link {
    color: #ff6960 !important;
    margin-bottom: 8px;
  }

  .user-app-navbar .nav-item {
    margin: 5px;
  }

  .navbar-toggler {
    padding-right: 0;
  }

  .navbar-toggler-icon {
    font-size: 38px;
    color: #fff;
    padding-top: 5px;
  }

  .get-app-btn {
    border: 2px solid #ff6961;
  }
}
