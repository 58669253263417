* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.chartjs-render-monitor {
  width: 500px !important;
  height: 200px !important;
}

.invalid-feedback {
  font-size: 90% !important;
}

.invalid-error {
  font-size: 90%;
  color: #dc3545;
}

.admin-navbar {
  background: #fff;
}

.admin-nav-bar ul li a,
.admin-nav-bar ul li span {
  color: #423c59 !important;
  font-size: 15px;
}

.admin-nav-bar ul li span:hover {
  color: #423c59 !important;
  cursor: default;
}

.admin-nav-bar ul li a:hover {
  color: #423c59 !important;
  cursor: pointer;
}

.user-img {
  width: 35px;
  height: 35px;
  margin-left: 10px;
}

#user-img-mobile {
  display: none;
}

#user-img-non-mobile {
  display: block;
}

.admin-sidebar-col {
  width: 15%;
  min-height: 1120px;
  background: #f26666;
}

.profile-img-sidebar {
  display: block;
  width: 100px;
  height: 100px;
  margin: auto;
  margin-top: 20px;
  border-radius: 50%;
}

.sidebar-user-name {
  color: #f2f2f2;
  text-transform: capitalize;
  text-align: center;
  font-size: 18px;
}

.admin-sidebar-col .card-title {
  font-size: 17px;
  font-weight: 600;
  color: #000;
}

.admin-sidebar-col .card-header {
  border-radius: 0;
}

.admin-sidebar-col .card-header:hover {
  cursor: pointer;
}

.admin-sidebar-col .accordion .card-header:after {
  content: "-";
  float: right;
  font-weight: bold;
}

.admin-sidebar-col .accordion .collapsed:after {
  content: "+";
  font-weight: bold;
}

.admin-sidebar-col .list-group-item {
  font-size: 15px;
  padding-left: 50px !important;
  border-radius: 0 !important;
  background: #343a40;
  color: #fff;
}

.admin-sidebar-col .list-group-item:hover {
  background: #000;
}

.content-col {
  width: 85%;
  background: #dbdbdb;
}

.inner-breadcrumb {
  border-radius: 0;
}

.breadcrumb {
  background: #f26666;
  border-left: 1px solid #fff;
}

.breadcrumb li {
  color: #fff;
}

.breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  color: #fff;
}

.page-heading-sec {
  background: #464646;
}

.page-heading-sec h6 {
  color: #fff;
}

.form-group {
  width: unset;
}

.content-col .form-group label {
  font-weight: 600;
  color: #000000;
  font-size: 600;
}

input[type="checkbox"] {
  cursor: pointer;
}

.form-group input:focus,
.form-group select:focus,
.form-group select option:focus,
.form-group textarea:focus {
  border: 1.5px #f26666 solid;
  box-shadow: none;
  outline: 0;
}

.input-position {
  width: 200px;
}

textarea.form-control {
  height: 200px;
}

.update-profile {
  max-width: 320px;
}

.save-btn {
  display: block;
  background: #f26666;
  padding: 7px 15px;
  color: #fff;
  text-align: center;
  border: 0;
}

.save-btn:focus {
  outline: 0;
}

.view-table-tr-color {
  background: #737373;
  color: #fff;
}

.orders-div-sec {
  border: 1px solid grey;
  margin: 20px;
}

.orders-div-sec ul {
  margin: 19px 43px;
  list-style: none;
}

.orders-div-sec table {
  margin-bottom: 20px;
}

.ag-header-viewport {
  background: #423c59;
  color: #fff;
  font-size: 15px;
}

.btn-save {
  background: #28a745;
  color: #fff;
  padding: 5px 25px;
  border: 0;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .admin-navbar {
    padding: 5px;
  }

  .user-img {
    width: 30px;
    height: 30px;
  }

  .admin-nav-bar ul li a,
  .admin-nav-bar ul li span {
    text-align: center;
  }

  .admin-sidebar-col {
    width: 100%;
    min-height: unset;
  }

  .content-col {
    width: 100%;
  }

  .inner-breadcrumb {
    display: none;
  }

  .col-form-label {
    text-align: left;
  }

  .inner-content {
    padding: 0;
    background: #f7f7f7;
  }

  .orders-div-sec {
    margin: 10px 0;
  }

  .orders-div-sec ul {
    margin: 20px;
  }
}

@media (max-width: 576px) {
  .user-img {
    width: 30px;
    height: 30px;
  }

  #user-img-mobile {
    display: block;
  }

  #user-img-non-mobile {
    display: none;
  }

  .col-form-label {
    width: 100%;
    text-align: left;
    padding-left: 15px;
  }

  .col-input {
    width: 100%;
    padding-left: 15px;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .user-img {
    width: 35px;
    height: 35px;
    margin: 4px;
  }

  .col-form-label {
    text-align: right;
  }

  .col-form-label {
    width: 38%;
    text-align: right;
    padding-right: 15px;
  }

  .col-input {
    width: 62%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .admin-sidebar-col {
    width: 33%;
  }

  .content-col {
    width: 67%;
  }

  .col-form-label {
    width: 45%;
    text-align: right;
    padding-right: 15px;
  }

  .col-input {
    width: 55%;
  }

  .orders-div-sec {
    margin: 10px 0;
  }
}

@media (min-width: 993px) and (max-width: 1224px) {
  .admin-sidebar-col {
    width: 25%;
  }

  .content-col {
    width: 75%;
  }

  .col-form-label {
    width: 30%;
    text-align: right;
    padding-right: 20px;
  }

  .col-input {
    width: 70%;
  }

  .orders-div-sec {
    margin: 10px 0;
  }
}

@media (min-width: 1225px) and (max-width: 1439px) {
  .admin-sidebar-col {
    width: 20%;
  }

  .content-col {
    width: 80%;
  }

  .col-form-label {
    width: 25%;
    text-align: right;
    padding-right: 20px;
  }

  .col-input {
    width: 45%;
  }
}

@media (min-width: 1440px) and (max-width: 1700px) {
  .admin-sidebar-col {
    width: 18%;
  }

  .content-col {
    width: 82%;
  }

  .col-form-label {
    width: 20%;
    text-align: right;
    padding-right: 25px;
  }

  .col-input {
    width: 45%;
  }
}

@media (min-width: 1701px) {
  .col-form-label {
    width: 15%;
    text-align: right;
    padding-right: 25px;
  }

  .col-input {
    width: 35%;
  }

  .col-input-proId {
    width: 100px;
  }

  .col-input-color-price {
    width: 200px;
  }

  .col-input select {
    width: 325px;
  }

  .col-input-textarea {
    width: 75%;
  }
}

@media (min-width: 993px) {
  .col-input-proId {
    width: 100px;
  }

  .col-input-color-price {
    width: 200px;
  }

  .col-input select {
    width: 325px;
  }

  .col-input-textarea {
    width: 70%;
  }
}

/* starts of dashboard */
.statistics .box {
  width: 23%;
  border: 1px solid #fff;
  margin: 1%;
  padding: 10px;
}

.statistics {
  background: #401b33;
}

.statistics .amount {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 0;
  color: #fff;
}

.statistics .stat {
  font-size: 18px;
  color: #fff;
}

@media (max-width: 575px) {
  .statistics .box {
    width: 48%;
  }
}

@media (min-width: 576px) and (max-width: 676px) {
  .statistics .box {
    width: 31.3%;
  }
}
/* starts of dashboard */

.canvasjs-chart-credit {
  display: none;
}

.cancelDelete {
  text-decoration: none !important;
  color: #7d7d7d;
  border: 1px solid;
}
