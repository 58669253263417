.footer-sec {
  background-size: cover;
  width: 100%;
  border-radius: 0;
  background: #fe6860;
  padding-top: 50px;
}

.store-div {
  max-width: 320px;
  margin: auto;
  margin-bottom: 15px;
}

.store-icons {
  width: 150px;
  margin: 5px;
  cursor: pointer;
}

.copyright {
  color: #fff;
  text-align: right;
}

.country-flag {
  margin-left: -1px;
  display: inline-block;
  width: 30px;
  height: 27px;
  margin-top: -2px;
  margin-right: 5px;
}

.update-region button {
  font-size: 12px;
  color: #ffffff;
  background: unset;
  border: 0;
  margin-right: 10px;
}

.update-region .dropdown-menu {
  font-size: 13px;
  color: #5f5f5f;
  cursor: pointer;
}

.update-region .dropdown-menu .country-flag {
  margin-right: 2px;
}

.update-region .dropdown-menu p:hover {
  background: #ccc;
}

.update-region .dropdown-item {
  padding: 0.25rem 1rem;
}

.update-region button:focus {
  box-shadow: none;
  outline: 0;
}

.dropup .dropdown-toggle::after {
  display: none;
}

.dropup .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

@media (max-width: 575px) {
  .store-div {
    max-width: 290px;
  }

  .store-icons {
    width: 135px;
  }

  .footer-logo {
    display: block;
    margin: auto;
  }

  .copyright {
    text-align: center;
    margin-top: 0 !important;
    margin-bottom: 15px;
  }
}

@media (max-width: 967px) and (min-width: 768px) {
  .store-div {
    max-width: 320px !important;
  }
}

@media (max-width: 1109px) and (min-width: 968px) {
  .store-div {
    max-width: 320px !important;
  }
}
